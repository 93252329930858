.choose-file::-webkit-file-upload-button {
    color: #212529;
    background-color: #ffc107;
    display: inline-block;
    border: none;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    white-space: nowrap;
    cursor: pointer;
}

#formDescription {
    resize: vertical;
}

@media (max-width: 768px) {
    #formDescription {
        min-height: 150px;
    }
}

a,
a:hover {
    color: black;
}