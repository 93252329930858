#landing-page {
    min-height: 100%;
    width: 100%;
    color: #222;
    /*background-color: #1090dc;*/
    background-color: #ffdd68;
    box-shadow: inset 0 0 3rem rgba(0, 0, 0, .5);
}

.cover-heading {
    font-size: 1.5rem;
}

.land-img {
    max-width: 80%;
}

.logo {
    width: 300px;
    max-width: 80%;
}

.col-sm {
    padding-right: 0;
    padding-left: 0;
}

.fb-sign-in {
    color: #fff;
    background-color: #3b5998;
}

.google-sign-in {
    color: #fff;
    background-color: #e15f4f;
}