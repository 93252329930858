.navbar-profile-image {
    width: 40px;
    height: 40px;
    object-fit: cover;
}

.dropdown-toggle::after {
    vertical-align: 0;
}

nav .dropdown-menu {
    min-width: 0;
}

.bottom-border {
    border-bottom: 2px solid #FFCC00 !important;
}