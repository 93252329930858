/**
* Map buttons
*/


/* For extra large screens - scale proportionally, but keep sidebar at max width */

@media (min-width: 1140px) {
    .map-page {
        max-width: 75%;
    }
    #sidebar {
        max-width: 245px;
    }
}

.map-page .btn-outline-warning {
    color: #333 !important;
    background-color: #fff;
}

.map-page .btn-outline-warning:hover {
    box-shadow: 0 0 0 0.2rem rgb(255 193 7 / 50%);
}

.map-page .btn-outline-warning.active:hover {
    box-shadow: 0 0 0 0.2rem rgb(255 193 7 / 50%);
    background-color: rgb(255, 193, 7) !important;
}

.map-page .btn-outline-warning:not(.active):hover {
    background-color: rgba(0, 0, 0, 0) !important;
}

.map-page .btn-img {
    height: 16px;
}

.leaflet-popup-content-wrapper {
    padding: 0 !important;
    border-radius: 16px !important;
}

.leaflet-popup-content-wrapper a {
    color: #fff !important;
}

.leaflet-popup-content {
    margin: 0 !important;
    width: auto !important;
}


/**
* Map Popup Card
**/

.map-popup-card {
    background-color: transparent;
    /* border-radius: 16px; */
    width: 280px !important;
    border: 0;
    margin: 0 !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    width: 100%;
}

.map-popup-card .card-title {
    margin-bottom: 0.2rem;
    text-overflow: ellipsis;
    max-width: 260px;
    line-height: 1.3rem;
    white-space: nowrap;
    overflow: hidden;
}

.map-popup-card .card-img-top {
    max-height: 200px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    object-fit: cover;
}

.map-popup-card .card-body {
    padding: 0.7rem 1.0rem;
}

.map-popup-card .link {
    color: #000 !important;
    font-weight: 500;
    transition: .3s color ease;
}

.map-popup-card .link:hover {
    text-decoration: none;
}

.spot-icon img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: 0 0 0 3px #fc0, 0 0 0 5px #00000033;
    /* box-shadow: 0 0 0 3px #FFFFFF33, 0 0 0 5px #fc0; */
}

.spot-icon-visited:after {
    content: url('/img/symbol-checked.png');
    position: absolute;
    top: -3px;
    right: -3px;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #00000033;
    width: 20px;
    height: 20px;
}

.spot-icon-favorite:after {
    content: url('/img/symbol-star.png');
    position: absolute;
    top: -3px;
    right: -3px;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #00000033;
    width: 20px;
    height: 20px;
}

.spot-icon-regular img {
    box-shadow: 0 0 0 3px #fc0, 0 0 0 5px #00000033;
    /* box-shadow: 0 0 0 3px #000, 0 0 0 5px #fc0; */
}

.spot-icon-hunt img {
    box-shadow: 0 0 0 3px #5fbcd3, 0 0 0 5px #00000033;
    /* box-shadow: 0 0 0 3px #000, 0 0 0 5px #5fbcd3; */
}

.spot-icon-challenge img {
    box-shadow: 0 0 0 3px #ff9955, 0 0 0 5px #00000033;
    /* box-shadow: 0 0 0 3px #000, 0 0 0 5px #ff9955; */
}

.spot-icon-promo img {
    box-shadow: 0 0 0 3px #cd87de, 0 0 0 5px #00000033;
    /* box-shadow: 0 0 0 3px #000, 0 0 0 5px #cd87de; */
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
    box-shadow: none;
}


/* favorite icon */

.map-page .star-favorite {
    font-weight: 700;
    color: #ffc107;
}

.map-page .star-not-favorite {
    font-weight: 400;
    color: black;
}

.map-page .select__control {
    border-color: #ffc107 !important;
}

.map-page .select__control:hover,
.map-page .select__control:focus {
    box-shadow: 0 0 0 0.2rem rgb(255 193 7 / 50%);
}

.map-page .select__control--is-focused {
    border-color: hsl(0, 0%, 80%) !important;
    box-shadow: 0 0 0 0.2rem rgb(255 193 7 / 50%) !important;
}

.map-page .select__control:not(.active):hover {
    background-color: rgba(0, 0, 0, 0) !important;
}


/* Tags select dropdown shows under button otherwise */

#sidebar .btn-group>.btn:focus,
#sidebar .btn-group>.btn:active,
#sidebar .btn-group>.btn.active,
#sidebar .btn-group-vertical>.btn:focus,
#sidebar .btn-group-vertical>.btn:active,
#sidebar .btn-group-vertical>.btn.active {
    z-index: 0;
}