.spot-page.container {
    max-width: 720px;
}

.spot-page section {
    margin-top: 1.6rem;
}

.spot-page .description p {
    color: #555;
}

.spot-page .card-text {
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
}

.spot-page .tags .btn {
    font-weight: 500;
    font-size: 0.8rem;
    margin-right: 0.25rem !important;
}

.spot-page .check-ins .card-text {
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    color: #555;
}

.spot-page .type-icon {
    height: 1.6rem;
    vertical-align: baseline;
    margin-right: 0.5rem;
}


/** Popup height fix for landing page **/

body.swal2-height-auto {
    height: 100% !important;
}