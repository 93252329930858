html,
body,
#root {
    height: 100%;
}

body {
    background-color: #f8f9fa !important;
}

.container {
    max-width: 965px;
}

.narrow-container {
    max-width: 720px !important;
}

.metrics {
    display: block;
    font-weight: 600;
    font-size: 0.8rem;
    color: #222;
}

.metrics>i {
    margin-right: 0.25rem;
}

.btn-outline-warning {
    color: #333 !important;
}

.link {
    color: #333 !important;
    font-weight: 500;
    transition: .3s color ease;
}

.border-only-bottom {
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid #dee2e6 !important;
}


/* Spinner  */

.spinner-overlay {
    background: #ffffff;
    color: #666666;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 5000;
    top: 0;
    left: 0;
    float: left;
    text-align: center;
    padding-top: 25%;
    opacity: .80;
}

.spinner-border {
    height: 3rem;
    width: 3rem;
}

.alert-outline-black {
    background-color: transparent;
    border: solid 1px #222;
    color: #222;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #fc0;
    color: #212529;
}

.form-control:focus {
    border-color: #fc0;
    color: #212529;
}

.spot-list-icon {
    height: 24px;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary:active {
    color: #000;
    background-color: transparent !important;
    border-color: transparent;
    box-shadow: 0 0 0 0.2rem rgb(108 117 125 / 50%);
}

.title-word {
    animation: color-animation 4s linear infinite;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    /* text-transform: uppercase; */
    /* font-size: 3em; */
    font-size: 1em;
}

.title-word-1 {
    --color-1: #DF8453;
    --color-2: #3D8DAE;
    --color-3: #E4A9A8;
}

.title-word-2 {
    --color-1: #DBAD4A;
    --color-2: #ACCFCB;
    --color-3: #17494D;
}

.title-word-3 {
    --color-1: #ACCFCB;
    --color-2: #E4A9A8;
    --color-3: #ACCFCB;
}

@keyframes color-animation {
    0% {
        color: var(--color-1)
    }
    32% {
        color: var(--color-1)
    }
    33% {
        color: var(--color-2)
    }
    65% {
        color: var(--color-2)
    }
    66% {
        color: var(--color-3)
    }
    99% {
        color: var(--color-3)
    }
    100% {
        color: var(--color-1)
    }
}