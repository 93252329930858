.dashboard .card-row {
    display: -ms-flexbox!important;
    display: flex!important;
    -ms-flex-direction: row!important;
    flex-direction: row!important;
    -ms-flex-wrap: nowrap!important;
    flex-wrap: nowrap!important;
    overflow: auto!important;
    padding-bottom: 0.5rem;
}

.dashboard .card {
    border-radius: 16px;
    border: 1px solid #dee2e6!important;
    border: 0;
    /* box-shadow: 0 .15rem 0.25rem rgba(0,0,0,.2)!important; */
    /* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); */
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    min-width: 240px;
    max-width: 240px;
    margin-right: 16px;
}

.dashboard .card-title {
    margin-bottom: 0;
    max-width: 200px;
    line-height: 1.3rem;
    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dashboard .card-body {
    padding: 1rem 1.1rem;
    background: linear-gradient(transparent, #222);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

.dashboard .card-img-top {
    border-radius: 16px;
    height: 215px;
    object-fit: cover;
}

.dashboard .metrics {
    display: block;
    color: #FFF;
    font-weight: 600;
}


/* Overlay is on bottom by default */

.card-img-overlay {
    padding: 0;
    color: #FFF !important;
    display: -ms-flexbox!important;
    display: flex!important;
    -ms-flex-align: end!important;
    align-items: flex-end!important;
    text-shadow: 0 .1rem .1rem rgba(0, 0, 0, .5);
}

.card-img-overlay-top {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
    border-radius: calc(0.25rem - 1px);
    padding: 0;
}

.dashboard .type-icon {
    height: 1.8rem;
    float: right;
}


/* Scroll */


/* width */

.dashboard ::-webkit-scrollbar {
    width: 10px;
    height: 5px;
}


/* Track */

.dashboard ::-webkit-scrollbar-track {
    background: #f1f1f1;
}


/* Handle */

.dashboard ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
}


/* Handle on hover */

.dashboard ::-webkit-scrollbar-thumb:hover {
    background: #888;
}

.leaderboard {
    line-height: 2em;
}